<script>
/******************************************************************************
 * Footer Component
 *****************************************************************************/
import {CLIENT_FULL, CLIENT_URL} from "@/config";
export default {
    data: function () {
        return {
            clientFull: CLIENT_FULL,
            clientUrl: CLIENT_URL
        }
    },
}
</script>

<template>
    <footer class="clearfix">
        <a
            class="footer-link"
            :href="clientUrl">{{ clientFull }}</a>
        <ul
            role="navigation"
            class="clearfix">
            <li>
                <a
                    class="footer-link"
                    href="https://www.ivugmbh.de/datenschutz">Datenschutz</a>
            </li>
            <li>
                <a
                    class="footer-link"
                    href="https://www.ivugmbh.de/impressum">Impressum</a>
            </li>
        </ul>
    </footer>
</template>

<style lang="scss" scoped>
    footer {
        background: map-get($client, "footerBg");
        color: map-get($client, "footerText");
    }

    a {
        display: block;

        @include respond-to("small") {
            float: left;
        }
    }

    ul {
        padding: 0;
        margin: 0;

        list-style: none;

        @include respond-to("small") {
            float: right;
        }
    }

    li {
        position: relative;
        float: left;

        &:first-child {
            float: left;
        }

        &:not(:first-child)::before {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;

            width: 1px;
            height: 20px;

            transform: translateY(-50%);

            background: lighten(map-get($client, "footerBg"), 12%);

            content: " ";
        }
    }

    .footer-link {
        display: block;

        padding: 1rem;

        color: map-get($client, "footerText");

        transition: background-color map-get($animation-speeds, "fast") linear;

        &:hover {
            background-color: darken(map-get($client, "footerBg"), 10%);

            text-decoration: none;
        }
    }
</style>
