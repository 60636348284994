<script>
/******************************************************************************
 * ZÄHLERSTÄNDE
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import CustomerData from "@/components/CustomerData/CustomerData";
import ReadingData from "./ReadingData/ReadingData";
import Meters from "./Meters/Meters";
import NavRow from "@/components/NavRow/NavRow";
import Icon from "@/components/Icon/Icon";
import router from "@/router";
export default {
    components: { PageSection, CustomerData, ReadingData, Meters, NavRow, Icon },
    computed: {
        proceedDisabled () {
            let isDisabled = false;
            const meterReadings = this.$store.getters.meterReadings.map(reading => {
                const meter = this.$store.getters.getMeterByNumbers(reading);
                reading.lastReadingValue = meter.meterReadingValue;
                reading.min = meter.meterValueMin;
                reading.max = meter.meterValueMax;
                return reading;
            });
            const isImplausible = (meter) => {
                if (meter.min !== 0) {
                    return meter.meterReadingValue < meter.min || meter.meterReadingValue > meter.max;
                } else if (meter.lastReadingValue) {
                    return meter.meterReadingValue < meter.lastReadingValue;
                } else {
                    return false;
                }

            };
            meterReadings.forEach(meter => {
                if (meter.meterReadingValue === "" || meter.meterReadingValue === undefined) {
                    isDisabled = true; // disabled for empty readings
                } else if (isImplausible(meter)) { // reading value exists
                    if ( meter.implausibleReason === "" || meter.implausibleReason === undefined // but implausible reason not selected
                        || ( // or reason = other and no other reason given
                            meter.implausibleReason === "Sonstiges"
                            && meter.implausibleReasonOther === ""
                        )
                    ) isDisabled = true;
                }
            });
            return isDisabled;
        }
    },
    methods: {
        proceed () {
            this.$store.dispatch("PROCEED_TO_CONFIRM");
        },
        prev () { router.push({name: "auth"}); }
    }
}
</script>

<template>
    <main>
        <page-section hdl="Zählerstände">
            Bitte geben Sie alle Zählerstände an.
        </page-section>
        <customer-data />
        <reading-data />
        <meters />
        <nav-row>
            <template slot="right">
                <button
                    type="button"
                    @click="proceed"
                    :disabled="proceedDisabled"
                    :aria-disabled="proceedDisabled">
                    Weiter
                    <icon
                        class="icon-next"
                        name="next" />
                </button>
            </template>
        </nav-row>
    </main>
</template>

<style lang="scss" scoped>
    .icon-next {
        float: right;

        margin: 7px 0 0 5px;
    }
</style>
